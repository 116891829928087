import StatusTag from "../../_components/StatusTag";
import { plugTypeName } from "../../_constants";

const columns = [
  {
    title: 'Plug Type',
    dataIndex: 'plugType',
    width: '25%',
    ellipsis: true,
    render: (text) => `${plugTypeName[text]} (${text})`,
    sorter: false,
  },
  {
    title: "Port ID",
    dataIndex: 'id',
    width: '25%',
    ellipsis: true,
    sorter: false
  },
  {
    title: "Status",
    dataIndex: 'status',
    width: '25%',
    ellipsis: true,
    render: (text, record) => { return <StatusTag status={record.status} /> },
    sorter: false
  },
  {
    title: 'Level',
    dataIndex: 'level',
    width: '25%',
    ellipsis: true,
    render: (text, record) => { return `${record.powerType} ${record.maxElectricPower}kW`},
    sorter: false,
  }
];

export { columns };
