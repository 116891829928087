import {truncateString, formatFullYearMonths} from "_helpers";
import StatusTag from "../_components/StatusTag";
import DeviceTags from "../_components/DeviceTags";
import ParagraphedTags from "../_components/ParagraphedTags";
import {statuses} from "_constants";
import {facilityType} from "./facilityType";

export const allCols = [
    {
        title: 'Station Id',
        dataIndex: 'stationId',
        sorter: true,
    },
    {
        title: 'Chargebox Id',
        dataIndex: 'chargeboxId',
        sorter: true,
    },
    {
        title: 'Status',
        dataIndex: 'operationalStatus',
        render: status => (
            <StatusTag status={status} />
        ),
        filters: statuses.map(item => ({
            text: item,
            value: item
        }))
    },
    {
        title: 'Message',
        dataIndex: 'message',
        'RC_TABLE_INTERNAL_COL_DEFINE': { style: { minWidth: '130px' } },
        render: value => value ? truncateString(value, 50, 50) : 'N/A'
    },
    {
        title: 'Make',
        dataIndex: 'make',
        sorter: true,
    },
    {
        title: 'Model',
        dataIndex: 'model',
        sorter: true,
    },
    {
        title: 'Network Operator',
        dataIndex: 'networkOperator',
        sorter: true,
    },
    {
        title: 'Enterprise',
        dataIndex: 'enterprise',
        sorter: true,
    },
    {
        title: 'Location',
        dataIndex: 'location',
        sorter: true,
    },
    {
        title: 'City',
        dataIndex: 'city',
        sorter: true,
    },
    {
        title: 'State',
        dataIndex: 'state',
        sorter: true,
    },
    {
        title: 'Firmware Version',
        dataIndex: 'firmwareVersion',
    },
    {
        title: 'Maintenance Mode',
        dataIndex: 'maintenanceMode',
        filters: [{
            text: 'true',
            value: 'true',
        }, {
            text: 'false',
            value: 'false',
        }],
        filterMultiple: false
    },
    {
        title: 'WebSocket Connection',
        dataIndex: 'webSocketConnection',
        filters: [{
            text: 'Connected',
            value: 'Connected',
        }, {
            text: 'Not Connected',
            value: 'Not Connected',
        }],
        filterMultiple: false
    },
    {
        title: 'Last Heartbeat',
        dataIndex: 'heartbeatTimestamp',
        render: value => value ? formatFullYearMonths(value) : 'N/A',
    },
    {
        title: 'Last Communication Timestamp',
        dataIndex: 'communicationTimestamp',
        render: value => value ? formatFullYearMonths(value) : 'N/A',
    },
    {
        title: 'Tags',
        dataIndex: 'tags',
        render: values => (
            <DeviceTags tags={values}/>
        ),
        filterSearch: true
    },
    {
        title: 'Generation',
        dataIndex: 'generation',
        sorter: true,
    },
    {
        title: 'Facility Type',
        dataIndex: 'facilityType',
        sorter: true,
        filters: Object.values(facilityType).map(item => ({
            text: item,
            value: item
        }))
    },
    {
        title: 'Plug In Success (%)',
        dataIndex: 'plugInSuccessRate',
        sorter: true,
        render: value => value && value !== 'null' ? value : 'N/A'
    },
    {
        title: 'Heartbeat (%)',
        dataIndex: 'heartbeatRate',
        sorter: true,
        render: value => value && value !== 'null' ? value : 'N/A'
    },
    {
        title: 'Connector Types',
        dataIndex: 'connectorTypes',
        render: values => (
            <ParagraphedTags tags={values} color={"orange"}/>
        ),
        filterSearch: true
    },
    {
        title: 'Power Levels (KW)',
        dataIndex: 'powerLevels',
        render: values => (
            <ParagraphedTags tags={values} color={"green"}/>
        )
    }
];