import { Table } from 'antd';
import RemoteActionsBtn from "_components/RemoteActionsButton";
import './styles.less';
import { useNavigate } from "react-router-dom";
import ExportCSVButton from '_components/ExportCSVButton';
import { usePermissions } from "../../_hooks/permissions.hook";
import * as permConsts from "../../_constants/permissionConstants";
import ColumnsSelector from '_components/ColumnsSelector';

const DevicesView = ({
  columns,
  data,
  onChange,
  pagination,
  loading,
  sorter,
  onSelectAll,
  onSelectChange,
  onDeselect,
  clearSelection,
  selectedRowIds,
  selectedAction,
  onClickConfirm,
  onClickAction,
  actions,
  currentFilters,
  onClickExport,
  setColumns
}) => {
  const navigate = useNavigate();
  const permissions = usePermissions()

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onChange: onSelectChange,
    onSelect: onDeselect,
    onSelectAll: onSelectAll,
    preserveSelectedRowKeys: true
  };

  const tableProps = {
    columns: columns,
    dataSource: data,
    loading: loading,
    sorter: sorter,
    onChange: onChange,
    pagination: pagination
  }

  const navigateToDetails = (record) => {
    navigate(`/devices/${record.id}`);
  }

  const table = selectedAction ?
    <Table
      rowClassName="clickable-row"
      rowKey={record => record.chargeboxId}
      rowSelection={{ ...rowSelection }}
      {...tableProps}
    /> :
    <Table
      rowClassName="clickable-row"
      rowKey={record => record.id}
      scroll={{ x: "scroll" }}
      {...tableProps}
      onRow={(record) => {
        return {
          onClick: () => {
            navigateToDetails(record);
          }
        }
      }}
    />;

  return (
    <>
      <div className={'header-button-group'}>
        <ExportCSVButton onClick={onClickExport} disabled={loading}>Export CSV</ExportCSVButton>
        {permissions.checkMultiplePermissions([
            {component: permConsts.components.devices, action: permConsts.actions.updateFirmware},
            {component: permConsts.components.devices, action: permConsts.actions.reset},
            {component: permConsts.components.devices, action: permConsts.actions.triggerMessageRequest},
            {component: permConsts.components.devices, action: permConsts.actions.getDiagnosticsRequest},
        ]) &&
          <RemoteActionsBtn
            title="Execute Action"
            selectedAction={selectedAction}
            showCancel={selectedRowIds.length === 0}
            clearSelection={clearSelection}
            confirm
            onClick={onClickAction}
            onClickConfirm={onClickConfirm}
            actions={actions}
            currentFilters={currentFilters}
          />
        }
      </div>
      <div className="devicesTable">
        <div>
          Edit Columns :
          <ColumnsSelector
            onChange={setColumns}
        />
        </div>
        {table}
      </div>
    </>
  );
}

export default DevicesView;