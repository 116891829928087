import DeviceView from "./DeviceView";
import {
    useDeviceChargingGroups,
    useDeviceEvse,
    useDeviceTag,
    useGetCertificatesForDevice,
    useOneDevice,
    useRemoteActions
} from "../../_hooks/devices.hook";
import { useParams } from "react-router-dom";
import { Spin, Tabs } from "antd";
import HealthView from "./HealthView";
import { useDeviceStates } from "_hooks/deviceStates.hook";
import DeviceStates from "./DeviceStatesView";
import PermissionCheck from "_partial/PermissionCheck";
import { useDeviceLogs } from "../../_hooks";
import { ocppColumns } from "./deviceLogsColumns";
// DO NOT REMOVE THESE CODE we might need in the future.
// import { useConfigurationKeys } from "../../_hooks/configurationKeys.hook";
// import { useDeviceConfiguration } from "../../_hooks/deviceConfiguration.hook";
// import DeviceConfigurations from "./DeviceConfigurationView";
// DO NOT REMOVE THESE CODE we might need in the future.
import DeviceOcppLogs from "./DeviceOcppLogsView";
import DeviceLiveLogsView from "./DeviceLiveLogsView";
import RemoteActionsBtn from "../../_components/RemoteActionsButton";
import RefreshBtn from "../../_components/RefreshButton";
import DeviceDiagnosticsView from "./DeviceDiagnosticsView";
import * as permConsts from "../../_constants/permissionConstants";
import { usePermissions } from "../../_hooks/permissions.hook";

const { TabPane } = Tabs;

const DeviceController = () => {
    const { deviceId } = useParams();
    const {
        deviceStates,
        deviceStatesLoading,
        deviceStatesHistory,
        deviceStatesHistoryLoading,
        deviceStatesUpdating,
        updateDeviceStatesHandler,
        getDeviceStatesHistoryHandler,
        getDeviceStates
    } = useDeviceStates();
    // DO NOT REMOVE THESE CODE we might need in the future.
    // const { configKeys } = useConfigurationKeys();
    // const {
    //   configuration, loading: deviceConfigLoading, updateConfigurationHandler, addConfigurationHandler
    // } = useDeviceConfiguration();
    // DO NOT REMOVE THESE CODE we might need in the future.
    const { device, loading, ...logic } = useOneDevice(deviceId);
    const { deviceCertificates } = useGetCertificatesForDevice(device);
    const { logsData, loading: deviceLogsLoading, pagination, sorter, onChange, columns: logColumns, getOcppLogs,
        timeFrame, onClickExportRawLogs
    } = useDeviceLogs(ocppColumns, device);
    const { selectedAction, onClickConfirm, onClickAction, actions } = useRemoteActions(device);
    const chargingGroupLogic = useDeviceChargingGroups(device);
    const deviceEvseLogic = useDeviceEvse(device);
    const tagLogic = useDeviceTag(device);
    const permissions = usePermissions();

    const diagnosticsLoading = false

    const keys = {
        details: "details",
        health: "health",
        state: "state",
        diagnostics: "diagnostics",
        configuration: "configuration",
        logs: "logs",
        liveLogs: "liveLogs",
    };

    const deviceHeader = device && <h1><b>{device.stationId} ({device.chargeboxId})</b></h1>;

    const deviceView = loading ? <Spin /> :
        <DeviceView
            loading={loading}
            device={device}
            deviceCertificates={deviceCertificates}
            {...logic}
            {...chargingGroupLogic}
            {...deviceEvseLogic}
            {...tagLogic}
        />;

    const healthView =
        <HealthView chargeboxId={device?.chargeboxId} />;

    const deviceStatesView = deviceStatesLoading ? <Spin /> : (<DeviceStates
        deviceStates={deviceStates}
        deviceStatesHistory={deviceStatesHistory}
        deviceStatesHistoryLoading={deviceStatesHistoryLoading}
        updateDeviceStatesHandler={updateDeviceStatesHandler}
        getDeviceStatesHistoryHandler={getDeviceStatesHistoryHandler}
        deviceStatesUpdating={deviceStatesUpdating}
        partyId={device?.partyId}
    />);

    // DO NOT REMOVE THESE CODE we might need in the future.
    // const deviceConfigurationView = deviceConfigLoading ? <Spin/> : (<DeviceConfigurations
    //   deviceConfig={configuration}
    //   configKeys={configKeys}
    //   addConfigHandler={addConfigurationHandler}
    //   updateConfigHandler={updateConfigurationHandler}
    // />);
    // DO NOT REMOVE THESE CODE we might need in the future.

    const deviceOcppLogsView = deviceLogsLoading ? <Spin /> : (<DeviceOcppLogs
        data={logsData}
        chargeboxId={device?.chargeboxId}
        columns={logColumns}
        pagination={pagination}
        sorter={sorter}
        onChange={onChange}
        timeFrame={timeFrame}
        onClickExportRawLogs={onClickExportRawLogs}
    />);

    const diagnosticsView = diagnosticsLoading ? <Spin /> : (
        <DeviceDiagnosticsView device={device} />);

    const deviceLiveLogsView = <DeviceLiveLogsView chargeboxId={device?.chargeboxId} />;

    const refreshData = () => {
        logic.getDevice();
        deviceEvseLogic.getEvseMessages();
        chargingGroupLogic.getChargingGroup();
        getOcppLogs();
        getDeviceStates();
    }

    return (
        <PermissionCheck page={permConsts.components.devices}>
            <div className={'header-button-group'}>
                <RefreshBtn
                    onClick={refreshData}
                    tooltipText={'Refresh Device Data'}
                />
                {permissions.checkMultiplePermissions([
                    {component: permConsts.components.devices, action: permConsts.actions.updateFirmware},
                    {component: permConsts.components.devices, action: permConsts.actions.reset},
                    {component: permConsts.components.devices, action: permConsts.actions.triggerMessageRequest},
                    {component: permConsts.components.devices, action: permConsts.actions.getDiagnosticsRequest},
                ]) &&
                    <RemoteActionsBtn
                        title="Execute Action"
                        selectedAction={selectedAction}
                        onClick={onClickAction}
                        onClickConfirm={onClickConfirm}
                        actions={actions}
                    />
                }
            </div>
            <div className="deviceContainer">
                {deviceHeader}
                <Tabs>
                    <TabPane tab="Details" key={keys.details}>
                        {deviceView}
                    </TabPane>
                    <TabPane tab="Health Data" key={keys.health}>
                        {healthView}
                    </TabPane>
                    {
                      permissions.checkMultiplePermissions([
                          {component: permConsts.components.devices, action: permConsts.actions.deviceStateFreevend},
                          {component: permConsts.components.devices, action: permConsts.actions.deviceStateAvailable},
                          {component: permConsts.components.devices, action: permConsts.actions.deviceStatePncEnabled},
                          {component: permConsts.components.devices, action: permConsts.actions.deviceStateMaintenanceMode},
                      ]) &&
                        <TabPane tab="State" key={keys.state}>
                            {deviceStatesView}
                        </TabPane>
                    }
                    <TabPane tab="Diagnostics" key={keys.diagnostics}>
                        {diagnosticsView}
                    </TabPane>
                    {/* DO NOT REMOVE THESE CODE we might need in the future. */}
                    {/* <TabPane tab="Configuration" key={keys.configuration}>
            {deviceConfigurationView}
          </TabPane> */}
                    {/* // DO NOT REMOVE THESE CODE we might need in the future. */}
                    <TabPane tab="Status Logs" key={keys.logs}>
                        {deviceOcppLogsView}
                    </TabPane>
                    <TabPane tab="Live Logs" key={keys.liveLogs}>
                        {deviceLiveLogsView}
                    </TabPane>
                </Tabs>
            </div>
        </PermissionCheck>
    );
};

export default DeviceController;
