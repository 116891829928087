import { Tag } from "antd";

const gray = '#808080';

const StatusTag = ({ status }) => {
  return (
    <Tag color={getStatusColor(status)}>
      {status ? status : 'UNKNOWN'}
    </Tag>
  );
}

const getStatusColor = (status) => {
  if (!status || status.length === 0) {
    return gray;
  }

  const lowerCase = `${status.toLowerCase()}`;

  switch (lowerCase) {
    case 'available':
    case 'finishing':
    case 'online':
      return 'green';
    case 'charging':
    case 'preparing':
    case 'reserved':
      return 'orange';
    case 'suspendedev':
    case 'suspendedevse':
    case 'unavailable':
    case 'unknown':
    case 'online_no_heartbeat':
    case 'offline':
      return gray;
    default:
      // assume error otherwise
      return 'red';
  }
}

export default StatusTag;