import {Table} from "antd";
import StatusTag from "../../../../_components/StatusTag";
import {useState} from "react";
import {useGetOperationalStatusLogQuery} from "../../../../_services/NeviAPI";
import moment from "moment-timezone";

const OperationalStatusTable = ({
                                       device,
                                       selectable,
                                       onChange
                                   }) => {
    const outageStatuses = ["UNKNOWN", "INOPERATIVE", "OUTOFORDER"];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
            title: 'Operational Status',
            dataIndex: 'status',
            align: 'center',
            render: (value) => <StatusTag status={value}/>
        },
        {
            title: "Timestamp",
            dataIndex: 'timestamp',
            align: 'center',
            render: (value) => value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "N/A",
        }
    ]

    const [queryParams, setQueryParams] = useState({
        evseUid: device?.chargeboxId,
        page: 0,
        size: 5,
        device: device
    })

    const {
        data = {},
        isFetching,
    } = useGetOperationalStatusLogQuery(queryParams, {skip: !queryParams.evseUid});

    const onSelect = (record) => {
        setSelectedRowKeys([record.status + record.timestamp])
        onChange(record.timestamp)
    }

    return (
        <Table
            loading={isFetching}
            columns={columns}
            dataSource={data.content}
            pagination={{
                total: data.totalElements,
                pageSize: data.size,
                onChange: (page, pageSize) => {
                    setQueryParams({
                        evseUid: device?.chargeboxId,
                        page: page - 1,
                        size: pageSize
                    })
                }
            }}
            size={"small"}
            rowSelection={selectable ? {
                type: "radio",
                selectedRowKeys: selectedRowKeys,
                onSelect: (record) => {
                    onSelect(record)
                },
                getCheckboxProps: (record) => ({
                    disabled: !outageStatuses.includes(record.status)
                })
            } : null}
            onRow={(record, rowIndex) => {
                return selectable && outageStatuses.includes(record.status) ? {
                    onClick: (event) => {
                        onSelect(record)
                    }
                } : null
            }
            }
            rowKey={record => record.status + record.timestamp}
            rowClassName={(record, index) => {
                return selectable && outageStatuses.includes(record.status) ? "clickable-row" : null
            }}
        />
    );
};

export default OperationalStatusTable;