import { Button, Modal, Spin, Tabs } from "antd";
import RefreshBtn from "../../_components/RefreshButton";
import StatusOverview from "./StatusOverview";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import TagsOverview from "./TagsOverview";
import ExportCSVButton from "../../_components/ExportCSVButton";

const { TabPane } = Tabs;

const HomeView = ({
  statusOverview,
  statusOverviewLoading,
  getStatusOverview,
  onClickExport
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);

  const mainOverview = "mainOverview";
  const manufacturerOverviews = "manufacturerOverviews";
  const manufacturers = ["ABB", "BTC", "Signet"];
  const tagsOverview = "tagsOverview";

  const statusOverviewView = (statusOverviewLoading ? <Spin /> :
      <Tabs>
          <TabPane tab="Overview" key={mainOverview}>
              <StatusOverview overview={statusOverview[mainOverview]}/>
          </TabPane>

          {statusOverview[manufacturerOverviews] ? manufacturers.map(manu => (
              <TabPane tab={manu} key={manu}>
                  <StatusOverview overview={statusOverview[manufacturerOverviews][manu]}/>
              </TabPane>
          )) : null}

          <TabPane tab="Tags" key={tagsOverview}>
              <TagsOverview overview={statusOverview[tagsOverview]}/>
          </TabPane>
      </Tabs>)

  // helper method to refresh overviews
  const refreshOverviews = () => {
    getStatusOverview();
  }

  const showModal = () => {
    setShowInfoModal(true);
  }

  const hideModal = () => {
    setShowInfoModal(false);
  }

  const infoModal = (
    <Modal className={'info-modal'} visible={showInfoModal} footer={null} onCancel={hideModal}>
      <p><b>Total Connections</b> represents the total number of websocket connections registered in CPO.</p>
      <p><b>Connected Evses</b> represents the total number of chargers that have websocket connections and are
        registered in OCPI locations with statuses (AVAILABLE, CHARGING, OUTOFORDER, etc.).</p>
      <p><b>Not Connected Evses</b> represents the total number of chargers that don't have a websocket connection.
        Some chargers may have been published to OCPI locations and never made websocket connections or dropped
        existing websockets connections, while others may have never been published from Asset Management.</p>
      <p><b>Connected Evses Statuses</b> groups the total number of connected chargers based on OCPI location statuses
        (AVAILABLE, CHARGING, OUTOFORDER, etc.). The lack of a status means the total number of chargers with that status is zero.</p>
      <p><b>Not Connected Evses Statuses</b> groups the total number of not connected chargers based on OCPI location statuses
        (likely INOPERATIVE, OUTOFORDER, or UNKNOWN). The lack of a status means the total number of chargers with that status is zero.</p>
      <p>Aside from the main overview, there are breakdowns for each of the three major charger manufacturers.
        L2s are included in the totals for BTC. Virtual charger totals are only included in the overview.</p>
    </Modal>);

  return (
    <>
      {infoModal}
      <div className={'header-button-group'}>
        <RefreshBtn onClick={refreshOverviews} tooltipText={'Refresh Overview'}/>
        <Button className={'help-button'} onClick={showModal} icon={<QuestionCircleOutlined />}/>
        <ExportCSVButton onClick={onClickExport} disabled={statusOverviewLoading}>Export CSV</ExportCSVButton>
      </div>
      {statusOverviewView}
    </>
  )
}

export default HomeView;