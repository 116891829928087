// station id, chargebox id, make, model, network operator, enterprise, location, address (MAYBE??), city, state
const evsesColumns = [
  {
    title: 'Station Id',
    dataIndex: 'stationId',
    sorter: true,
  },
  {
    title: 'Chargebox Id',
    dataIndex: 'chargeboxId',
    sorter: true,
  },
  {
    title: 'Make',
    dataIndex: 'make',
    sorter: true,
  },
  {
    title: 'Model',
    dataIndex: 'model',
    sorter: true,
  },
  {
    title: 'Network Operator',
    dataIndex: 'networkOperator',
    sorter: true,
  },
  {
    title: 'Enterprise',
    dataIndex: 'enterprise',
    sorter: true,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    sorter: true,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: true,
  },
  {
    title: 'City',
    dataIndex: 'city',
    sorter: true,
  },
  {
    title: 'State',
    dataIndex: 'state',
    sorter: true,
  },
];

export { evsesColumns };